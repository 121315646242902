/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function getRandomInt(min, max) {
    return Math.random() * (max - min) + min;
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
          setTimeout(function() {
              $('body').fadeIn('fast');
              $('a[href^="#"]').on('click',function (e) {
                    e.preventDefault();

                    var target = this.hash;
                    var $target = $(target);

                    $('html, body').stop().animate({
                        'scrollTop': $target.offset().top
                    }, 900, 'easeInOutCubic', function () {
                        window.location.hash = target;
                    });
                });
          },400);
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
          setTimeout(function() {
              $('#hero').backgroundParallaxScroll();
              if ($( window ).width()>767) {
                $('.hero-side-menu > li:first-of-type > .side-content').fadeIn('fast');
                $('.hero-side-menu > li:first-of-type > .side-link').fadeIn('fast');
              }
            $('.hero-side-menu').accordion({
              icons: { "header": "fa fa-plus-square-o fa-small float-right", "activeHeader": "fa fa-minus-square-o fa-small float-right" }
            });
          }, 500);
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
      
      'single_post': {
        init: function() {
        },
          finalize: function() {
            setTimeout(function() {
              $('#hero').backgroundParallaxScroll();
                if ($( window ).width()>767) {
                $('.hero-side-menu > li:first-of-type > .side-content').fadeIn('fast');
                $('.hero-side-menu > li:first-of-type > .side-link').fadeIn('fast');
              }
                $('.main').find('p > img').each( function() {
                    var imgUrl = $(this).attr('src'),
                        imgAlt = $(this).attr('alt'),
                        ra = getRandomInt(-1,1);
                    
                    console.log(imgUrl);
                    $(this).parent().addClass('photoframe').css({'background-image':'url(' + imgUrl + ')','-ms-transform': 'rotate(' + ra + 'deg)', '-webkit-transform': 'rotate(' + ra + 'deg)', 'transform': 'rotate(' + ra + 'deg)'}).append('<img src="'+ bv.templateUrl + '/dist/images/photoframe.png" alt="">');
                    $(this).remove();
                });
                
                $('.main').find('figure > img').each( function() {
                    var imgUrl = $(this).attr('src'),
                        imgAlt = $(this).attr('alt'),
                        ra = getRandomInt(-1,1);
                    
                    console.log(imgUrl);
                    $(this).parent().addClass('photoframe').css({'background-image':'url(' + imgUrl + ')','-ms-transform': 'rotate(' + ra + 'deg)', '-webkit-transform': 'rotate(' + ra + 'deg)', 'transform': 'rotate(' + ra + 'deg)'}).append('<img src="'+ bv.templateUrl + '/dist/images/photoframe.png" alt="">');
                    $(this).remove();
                });
                $('.hero-side-menu').accordion({
              icons: { "header": "fa fa-plus-square-o fa-small float-right", "activeHeader": "fa fa-minus-square-o fa-small float-right" }
            });
          }, 500);
          }
      },
      'blog': {
        init: function() {
        },
          finalize: function() {
              
          }
      }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
